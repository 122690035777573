var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "d-flex flex-column mb-4",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between",
              on: { click: _vm.openFileBottomSheet },
            },
            [
              _c("span", { staticClass: "my-1" }, [
                _vm._v(_vm._s(_vm.$t("Select image / file"))),
              ]),
              _c("v-btn", { attrs: { color: "primary", rounded: "" } }, [
                _vm._v(_vm._s(_vm.$t("Choice"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "imagePreviewWrapper d-none",
              attrs: { id: "previewContainer" },
            },
            [
              _vm.selectedFile == "IMAGE"
                ? _c("img", {
                    staticClass: "img-preview",
                    attrs: { src: _vm.pickedfile },
                  })
                : _vm._e(),
              _vm.selectedFile == "FILE"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-items-center justify-content-center",
                    },
                    [
                      _c("iconly", {
                        attrs: {
                          type: "bulk",
                          name: "document",
                          color: "#8C489F",
                          size: "250",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.fileName) + "." + _vm._s(_vm.fileExtension)
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Title"))),
          ]),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              "single-line": "",
              type: "text",
              rules: _vm.titleRules,
              required: "",
              counter: "128",
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Description"))),
          ]),
          _c("v-textarea", {
            attrs: {
              outlined: "",
              type: "text",
              counter: "600",
              rules: _vm.descriptionRules,
              required: "",
            },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _c(
            "div",
            { staticClass: "mt-1 text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "col-12",
                  attrs: {
                    color: "primary",
                    large: "",
                    loading: _vm.submit_loading,
                    disabled: _vm.submit_loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("vue-bottom-sheet", { ref: "fileBottomSheet" }, [
        _c(
          "section",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c(
              "v-list",
              {
                staticClass: "w-100 pt-0 mt-auto",
                attrs: { light: "", shaped: "" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-center mb-1 d-block f14 font-weight-bold",
                  },
                  [_vm._v(_vm._s(_vm.$t("File selection")))]
                ),
                _c("input", {
                  ref: "imageInput",
                  attrs: { type: "file", accept: "image/*", hidden: "" },
                  on: { input: _vm.pickImage },
                }),
                _c("input", {
                  ref: "fileInput",
                  attrs: {
                    type: "file",
                    accept: ".doc,.docx,.xls,.xlsx,.pdf,.txt",
                    hidden: "",
                  },
                  on: { input: _vm.pickFile },
                }),
                _c(
                  "v-list-item",
                  { attrs: { ripple: "" }, on: { click: _vm.selectFile } },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "my-0 align-self-center" },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center rounded-lg p-2",
                            attrs: { color: "#F1F0FF", elevation: "0" },
                          },
                          [
                            _c("iconly", {
                              attrs: {
                                type: "bulk",
                                name: "document",
                                color: "#8C489F",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-title",
                      { staticClass: "f14", attrs: { tag: "span" } },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("Select file")))])]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { attrs: { ripple: "" }, on: { click: _vm.selectImage } },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "my-0 align-self-center" },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center rounded-lg p-2",
                            attrs: { color: "#fff3e1", elevation: "0" },
                          },
                          [
                            _c("iconly", {
                              attrs: {
                                type: "bulk",
                                name: "image",
                                color: "#f8b758",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-title",
                      { staticClass: "f14", attrs: { tag: "span" } },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("Select image")))])]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "my-2",
                    attrs: { block: "", color: "primary", rounded: "" },
                  },
                  [_vm._v(_vm._s(_vm.$t("Close")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }